.services-container {
  background-color:#012363;
  margin: 50px 0px;
  box-shadow: 0 0 1rem black;

 
  

  h4 {
    font-family: serif;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    color: white;
   margin-top: 50px;
    margin-left: 50px ;
    
  }
  h3{
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    color: white;
   font-family: serif;
    margin-left: 50px ;
  }
  h6{
    color:#333;
  }
  
  h5 {

    font-size: 18px;
    font-weight: 400;
    margin-bottom: 40px;
    max-width: 80%;
    color: white;
    margin-left: 100px;
  }
  
  .services-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: -50px;
    
    .service-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      img {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
        color: white;
      }

      h5 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-left: 0px;
        
      }

      p {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: white;
      }
    }
    
    .center-item {
      grid-column: span 3;
    }
  }

  @media screen and (max-width: 992px) {
    h5{
      margin-left: 60px;
    }
    .services-list {
      grid-template-columns: repeat(2, 1fr);

      .center-item {
        grid-column: span 2;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .services-list {
      grid-template-columns: 1fr;

      .center-item {
        grid-column: span 1;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .service-item {
      img {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
      }

      h5 {
        font-size: 18px;
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
      }
    }
  }
}
