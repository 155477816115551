// .about-us {

//   overflow: hidden;


//   padding: 50px;
 
//   .img1{
//     width: 500px;
//     height: 300px;
//     margin-left: 720px;
//     margin-top: -425px;
//     z-index: 1;
//   }
//   h3 {
//     margin-left: 300px;
//     font-size: 36px;
//     font-weight: bold;
//     margin-bottom: 25px;
//   color: black;
//     margin-bottom: -80px;
//     margin-top: 100px;
//     font-size: 3rem;
//     font-family:sans-serif;
//   }

//   p {
//     font-size: 18px;
//     line-height: 1.5;

//     max-width: 650px;
//     margin: 0 auto;
//     margin-left: 50px;
//     margin-top: 200px;
//     z-index: 1;

//   }

//   .p2{
//     font-size: 18px;
//     line-height: 1.5;
   
//     max-width: 700px;
//     margin: 0 auto;
//     margin-left: 520px;
//     margin-top: 20px;
    
    
//   }
  

  


.about-us {
  text-align: center;

  h3 {
    color: black;
    margin-bottom: 20px; // Adjust margin as needed

        font-size: 36px;
        font-weight: bold;
        margin-bottom: 25px;
      color: black;
        margin-top: 50px;
  
        font-family:sans-serif;
  }

  .div1 {
    display: flex;
    flex-direction: column;
    align-items: center;

    .div2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 40px; // Adjust margin as needed

      p {
     
      font-size: 20px;
        max-width: 700px;
        margin-right: 200px; // Adjust margin as needed
       
        
      }

      .imageContainer{
        width: 300px; /* or any desired width */
        height: 300px; /* or any desired height */
        margin: 0 auto 20px;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: 0 0 2rem rgba(226, 213, 213, 0.719);
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit:fill;
         }}
    }
    .div3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px; // Adjust margin as needed
      position: relative;
      .imageContainer2{
        width:500px; /* or any desired width */
        height: 300px; /* or any desired height */
        margin: 0 auto 20px;
        overflow: hidden;
        border-radius: 10px;
       
        box-shadow: 0 0 2rem rgba(226, 213, 213, 0.719);
      .video2 {
        width: 100%;
        height: 100%;
        object-fit:fill;
      }
     
      
      .video2::-webkit-media-controls-play-button {
        display: none !important;
      }
      
    
      
      .playPauseButton {
        position: absolute;
        top: 33%;
   
     left: 16%;
       font-size: 100px;
        color: rgb(201, 20, 20);
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
    }
      .p3 {
    font-size: 20px;
 margin-left: 30px;
        max-width: 700px; // Adjust margin as needed
      }

      .img2 {
        height: 350px;
        width: 400px; // Adjust image size as needed
       margin-top: 20px;
        margin-left:100px;
        margin-right: 100px;
      }
    }
    
  }
  @media only screen and (max-width: 992px) {
    padding: 30px;

    h3 {
      color: black;
    margin-bottom: 20px; // Adjust margin as needed

        font-size: 36px;
        font-weight: bold;
        margin-bottom: 25px;
      color: black;
        margin-top: 50px;
  
        font-family:sans-serif;
      
    }
    .div1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      
  
      .div2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px; // Adjust margin as needed
  gap: 30px;
        p {
       
        font-size: 20px;
          max-width: 700px; // Adjust margin as needed
          margin-right: 0px;
          
        }
  
        .img1 {
         height: 230px;
         width: 400px; // Adjust image size as needed
         margin-top: 30px;
         margin-left:100px;
         margin-right: 100px;
         border-radius: 50%;
        }
      }
      .div3 {
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px; // Adjust margin as needed
        position: relative;
        .imageContainer2{
          height: 230px;
         width: 310px;; /* or any desired height */
         
          overflow: hidden;
          border-radius: 10px;
          
          box-shadow: 0 0 2rem rgba(226, 213, 213, 0.719);
        .video2 {
          width: 100%;
          height: 100%;
          object-fit:fill;
          
        }
        .video2::-webkit-media-controls-play-button {
          display: none !important;
        }
        .playPauseButton {
          position: absolute;
          top: 10%;
     
       left: 38%;
         font-size: 100px;
          color: rgb(201, 20, 20);
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
      }
        .p3 {
      font-size: 20px;
          // margin-right: 50px;
          margin-top: 50px;
          margin-left: 0px;
          max-width: 800px; // Adjust margin as needed
        }
  
        .img2 {
          height: 350px;
          width: 400px; // Adjust image size as needed
         margin-top: 20px;
          margin-left:100px;
          margin-right: 100px;
        }
      }
      
    }
  }
}
