@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

$primary-color: #007bff;
$secondary-color: #6c757d;
$white-color: #ffffff;


.portfolio {
  font-family: 'Montserrat', sans-serif;
  margin: 20px;
  background-color: #ffff;
  width: 100%;
  margin-left: 0px;
  
  
  h3 {
    font-family: serif;
    margin-top: 50px;
    font-size: 3rem;
    color: black;
    font-weight: bold;
   
    margin-bottom: 20px;
    text-align: center;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    
    > div {
      width: 50%;
      padding: 0 10px;
      margin-bottom: 40px;
      margin-left: 0px;
      
      h4 {
        font-size: 24px;
        color: #6c757d;
        margin-bottom: 10px;
        margin-left: 15px;


      }
      
      .card {
        border: none;
        box-shadow: 0 0 10px rgba(0,0,0,.2);
        margin-bottom: 20px;
        
        
        .card-body {
          padding: 20px;
          
          
          .row {
            display: flex;
            flex-wrap: wrap;
            
            .col-md-4 {
              padding: 0 10px;
              margin-bottom: 20px;
              
              img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 768px) {
    .row {
      flex-direction: column;
      
      > div {
        width: 100%;
        padding: 0;
      }
    }
  }
}
