.carousel-item img {
  height: 700px;
  object-fit: cover;
}

.btext {
  margin-top: -400px;
}

/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  .carousel-item img {
    height: 500px;
  }
  
  .btext {
    margin-top: -250px;
  }
}

/* Media query for screens between 768px and 991px */
@media (min-width: 768px) and (max-width: 991px) {
  .carousel-item img {
    height: 600px;
  }
  
  .btext {
    margin-top: -350px;
  }
}

/* Media query for screens larger than 992px */
@media (min-width: 992px) {
  .carousel-item img {
    height: 700px;
  }
  
  .btext {
    margin-top: -400px;
  }
}
