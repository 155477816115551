$icon-size: 60px;

// Style the image
.img1 {
  margin-left: 600px;
  margin-top: -100px;
}

.img2 {
  margin-left: 530px;
  margin-top: -150px;
}

.img3 {
  margin-left: 670px;
  margin-top: -195px;
}

img {
  width: $icon-size;
  height: $icon-size;
  object-fit: contain; // This will make the image fit inside the container without stretching or distorting
}
.message-container{
  width:100%;
  background-color: rgba(255, 255, 255, 0.945);
  margin-top: -450px;
  margin-left: 100px;
  font-weight: bold;
  z-index: 1;
  height: 500px;
  .close-button{
    width: 20px;
    color: black;
    border: none;
    margin-left: 1090px;
    
    z-index: 1;
    
  }
  .p{
    margin-top: 100px;
    text-align: center;
    font-size: 1.5rem;
    margin-left: 10px;
  }
  @media (max-width:650px) {
    
      display: none;
    }

}

.container {
  position: absolute;
  z-index: 1;

  .tag1 {
    margin-top: -420px;
    margin-left: 110px;
    color: #fff;
    font-size: 2rem;
    font-family: sans-serif
  }

  .tag2 {
    margin-left: 110px;
    color: #fff;
    font-size: 3rem;
    font-family:sans-serif;
    margin-top: -15px;
    font-weight:900;
  }

  .tag3 {
    color: #fff;
    margin-left: 110px;
    margin-top: -15px;
    font-size: 3rem;
    font-family:sans-serif;
    font-weight:900;
  }

  .tag4 {
    font-size: 2rem;
    color: #fff;
    margin-left: 110px;
    margin-top: -15px;
  }
  @media (min-width: 240px) and (max-width:990px) {

    .tag1 {
      margin-top: -320px;
      margin-left: 30px;
      color: #fff;
      font-size: 2rem;
      font-family: sans-serif
    }
  
    .tag2 {
      margin-left: 30px;
      color: #fff;
      font-size: 3rem;
      font-family:sans-serif;
      font-weight:900;
      margin-top: -15px;
    }
  
    .tag3 {
      color: #fff;
      margin-left: 30px;
      margin-top: -15px;
      font-size: 3rem;
      font-family:sans-serif;
      font-weight:900;
    }
  
    .tag4 {
      font-size: 2rem;
      color: #fff;
      margin-left: 30px;;
      margin-top: -15px;
    }}
    @media (min-width:650px) and (max-width: 820px) {

    

      .form-container {
        width: 250px;
       margin-top: -300px;
      margin-left: 380px;
        border-radius: 30px;
        background-color: rgba(0, 0, 0, 0.664);
  
        /* Adjust this value to properly position the form container on smaller screens */
        h3 {
          font-size: 1.5rem;
          margin-bottom: 20px;
        }
  
        div {
          margin-bottom: 10px;
  
  
          input,
          textarea {
            font-size: 1rem;
            padding: 0.5rem;
          }
        }
  
        button[type="submit"] {
          font-size: 1rem;
          padding: 8px;
        }
      }
  
  
  
  
    }
   
        @media (min-width:820px) and (max-width: 1070px) {

    

          .form-container {
            width: 300px;
           margin-top: -300px;
          margin-left: 500px;
            border-radius: 30px;
            background-color: rgba(0, 0, 0, 0.664);
      
            /* Adjust this value to properly position the form container on smaller screens */
            h3 {
              font-size: 1.5rem;
              margin-bottom: 20px;
            }
      
            div {
              margin-bottom: 10px;
      
      
              input,
              textarea {
                font-size: 1rem;
                padding: 0.5rem;
              }
            }
      
            button[type="submit"] {
              font-size: 1rem;
              padding: 8px;
            }
          }
      
      
      
      
        }
        @media (min-width:1024px) and (max-width: 1300px) {

    

          .form-container {
            width: 300px;
           margin-top: -300px;
          margin-left: 700px;
            border-radius: 30px;
            background-color: rgba(0, 0, 0, 0.664);
      
            /* Adjust this value to properly position the form container on smaller screens */
            h3 {
              font-size: 1.5rem;
              margin-bottom: 20px;
            }
      
            div {
              margin-bottom: 10px;
      
      
              input,
              textarea {
                font-size: 1rem;
                padding: 0.5rem;
              }
            }
      
            button[type="submit"] {
              font-size: 1rem;
              padding: 8px;
            }
          }
      
      
      
      
        }
        
        @media (min-width:1460px) and (max-width: 1488px) {

    

          .form-container {
            width: 300px;
           margin-top: -300px;
          margin-left: 800px;
            border-radius: 30px;
            background-color: rgba(0, 0, 0, 0.664);
      
            /* Adjust this value to properly position the form container on smaller screens */
            h3 {
              font-size: 1.5rem;
              margin-bottom: 20px;
            }
      
            div {
              margin-bottom: 10px;
      
      
              input,
              textarea {
                font-size: 1rem;
                padding: 0.5rem;
              }
            }
      
            button[type="submit"] {
              font-size: 1rem;
              padding: 8px;
            }
          }
      
      
      
      
        }
           
        @media (min-width:1400px) and (max-width: 1460px) {

    

          .form-container {
            width: 300px;
           margin-top: -300px;
          margin-left: 900px;
            border-radius: 30px;
            background-color: rgba(0, 0, 0, 0.664);
      
            /* Adjust this value to properly position the form container on smaller screens */
            h3 {
              font-size: 1.5rem;
              margin-bottom: 20px;
            }
      
            div {
              margin-bottom: 10px;
      
      
              input,
              textarea {
                font-size: 1rem;
                padding: 0.5rem;
              }
            }
      
            button[type="submit"] {
              font-size: 1rem;
              padding: 8px;
            }
          }
      
      
      
      
        }
        
      
  @media (max-width: 650px) {

    .img1,
    .img2,
    .img3 {
      display: none;
    }

    @media (max-width:650px) {
      .form-container {
        display: none;
      }

    }

  
  }


}

.form-container {
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 950px;
  margin-right: -170px;

  border-radius: 60px;
  background-color: rgba(0, 0, 0, 0.664);

  margin-top: -300px;

  h3 {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
    color: #fff;
  }


  div {
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 5px;
      font-size: 18px;
    }

    input,
    textarea {
      border: none;
      border-bottom: 1px solid #fff;
      padding: 0.5rem;
      width: 100%;
      background-color: rgba(255, 255, 255, 0);
      color: #fff;

      &:focus {
        outline: none;
        border-bottom-color: #007bff;
      }


    }

  }

  button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #008080;
    border: none;
    border-radius: 5px;
    transition: background-color 0.2s;

    &:hover {
      background-color: #006666;
      cursor: pointer;
    }
  }

  
}